<template>
  <div>
    <v-toolbar flat>
      <v-row class="align-center justify-center">
        <v-btn-toggle
          mandatory
          dense
          v-model="type"
          return-object
          v-if="allowedViews.length != 1"
        >
          <v-btn
            outlined
            text
            v-for="view in views"
            :key="view.name"
            :value="view"
            >{{ view.title }}</v-btn
          >
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <template v-if="showDateNav">
          <v-btn outlined text @click="setToday" class="mr-2">Heute</v-btn>
          <v-menu
            v-model="menu"
            offset-y
            :nudge-left="40"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on"
                ><v-icon>mdi-calendar</v-icon></v-btn
              >
            </template>
            <v-date-picker
              color="primary"
              v-if="type.name == 'month'"
              type="month"
              v-model="focusMonth"
              locale="de-CH"
              @input="menu = false"
            ></v-date-picker>
            <v-date-picker
              v-else
              color="primary"
              v-model="focus"
              locale="de-CH"
              first-day-of-week="1"
              :show-week="type == 'week'"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <v-btn icon @click="prev()" v-if="!$vuetify.breakpoint.mobile">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon @click="next()" v-if="!$vuetify.breakpoint.mobile">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <TermPicker text outlined v-model="term" :range="[-2, 1]" />
        </template>
        <v-spacer></v-spacer>
        <slot></slot>
      </v-row>
    </v-toolbar>

    <v-card :loading="loading">
      <v-system-bar>{{ title }}</v-system-bar>
      <v-calendar
        v-if="type.calendar"
        v-bind="$attrs"
        ref="calendar"
        first-interval="7"
        interval-count="14"
        v-model="focus"
        @click:date="showDay"
        @click:event="showEvent"
        :weekdays="[1, 2, 3, 4, 5, 6]"
        locale="de-CH"
        :type="type.calendar ? type.calendar : 'month'"
        :events="items"
        @change="change"
        color="primary"
        :show-week="type.name == 'term'"
        :locale-first-day-of-year="4"
        :start="start"
        :end="end"
      >
        <template v-slot:event="{ event, eventSummary }">
          <v-badge
            inline
            bordered
            left
            dot
            :color="eventColor(event.event)"
            v-if="event.event && eventColor(event.event)"
          >
            <component
              :is="{ render: eventSummary }"
              :class="event.canceled ? 'canceled' : ''"
              class="ma-1"
            ></component>
          </v-badge>

          <component
            :is="{ render: eventSummary }"
            v-else
            class="mx-1"
          ></component>
        </template>
        <template v-slot:day-body="{ date }">
          <div
            v-if="cal && date == cal.times.now.date"
            class="v-current-time"
            :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
    </v-card>

    <v-bottom-sheet v-model="dialog" width="500">
      <EventSheet
        v-if="selectedEvent.event"
        :value="selectedEvent.event"
        @close="dialog = false"
      ></EventSheet>
      <HolidaySheet
        v-if="selectedEvent.holiday"
        :value="selectedEvent.holiday"
        @close="dialog = false"
      ></HolidaySheet>
      <NoteSheet
        v-if="selectedEvent.note"
        :value="selectedEvent.note"
        @close="dialog = false"
      ></NoteSheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EventSheet from "common/components/EventSheet.vue";
import HolidaySheet from "common/components/HolidaySheet.vue";
import NoteSheet from "common/components/NoteSheet";
import TermPicker from "common/components/TermPicker";

import {
  addDays,
  formatDatespan,
  formatDate,
  formatMonth,
  today,
} from "common/utils/date";
import { holidayIcon } from "common/utils/icons";

export default defineComponent({
  name: "Calendar",
  components: {
    EventSheet,
    NoteSheet,
    HolidaySheet,
    TermPicker,
  },
  props: {
    allowedViews: { type: Array, default: () => [] },
    name: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    events: { type: Array, default: () => [] },
    exams: { type: Array, default: () => [] },
    holidays: { type: Array, default: () => [] },
  },
  data() {
    return {
      focus: "",
      dialog: false,
      menu: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      ready: false,
      start: "2022-01-01",
      end: "2022-01-01",
      term: null,
      type: { name: "month", calendar: "month" },
      types: [
        { name: "day", title: "Tag", calendar: "day" },
        { name: "week", title: "Woche", calendar: "week" },
        { name: "month", title: "Monat", calendar: "month" },
        { name: "term", title: "Semester", calendar: "custom-weekly" },
      ],
    };
  },
  watch: {
    dateSpan() {
      this.$emit("change", this.start, this.end);
    },
    term() {
      if (this.term) {
        this.start = addDays(this.term.firstSchoolDay, -1);
        this.end = this.term.endDate;
      }
    },
    type() {
      localStorage.setItem("calendarType" + this.name, this.type.name);
      if (!this.showDateNav && this.term) {
        this.start = addDays(this.term.firstSchoolDay, -1);
        this.end = this.term.endDate;
      }
    },
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    dateSpan() {
      return [this.start, this.end];
    },
    focusMonth: {
      get() {
        return this.focus.substring(0, 7);
      },
      set(newValue) {
        this.focus = newValue + "-01";
      },
    },
    person() {
      return this.$_profilePerson;
    },
    items() {
      const items = [];

      // add events
      for (const item of this.events) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);

        items.push({
          name: item.description,
          icon: "mdi-calendar",
          category: "Termin",
          start: eventStart,
          end: eventEnd,
          dateSpan: formatDatespan(item.startDate, item.endDate, true),
          color: item.past ? "success" : "success darken-2",
          timed: item.startTime && item.endTime,
          event: item,
          canceled: item.canceled,
        });
      }

      // add holidays
      for (const item of this.holidays) {
        const start = new Date(item.startDate);
        const end = new Date(item.endDate);
        items.push({
          name: item.name,
          category: "Ferien",
          start: start,
          end: end,
          icon: holidayIcon(item),
          dateSpan: formatDatespan(item.startDate, item.endDate, true),
          color: "warning",
          timed: false,
          holiday: item,
          canceled: false,
        });
      }

      for (const item of this.exams) {
        const eventStart = new Date(
          item.date + "T" + item.period.startTime // + '+01:00'
        );
        const eventEnd = new Date(
          item.date + "T" + item.period.endTime // + '+01:00'
        );

        items.push({
          name:
            (item.course && item.course.subject
              ? item.course.code
              : item.title) +
            (" " + item.course.teachers.map((el) => el.code).join(",")),

          category:
            item.type.description +
            (item.plannedExam ? " (eingetragen)" : " (nicht eingetragen)"),
          start: eventStart,
          end: eventEnd,
          dateSpan: formatDatespan(item.date, item.date, true),
          color: "danger",
          timed: true,
          note: item,
        });
      }

      return items;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    views() {
      if (this.allowedViews.length == 0) {
        return this.types;
      }
      return this.types.filter((el) => this.allowedViews.includes(el.name));
    },
    showDateNav() {
      return !["list", "term"].includes(this.type.name);
    },
    title() {
      if (!this.showDateNav) {
        return this.term ? this.term.fullText : "";
      } else if (this.type.calendar == "week") {
        return formatDatespan(
          this.start,
          this.end,
          !this.$vuetify.breakpoint.mobile
        );
      } else if (this.type.calendar == "day") {
        return formatDate(this.focus, !this.$vuetify.breakpoint.mobile);
      }
      return formatMonth(this.focus);
    },
  },
  methods: {
    change({ start, end }) {
      this.start = start.date;
      this.end = end.date;
    },
    eventColor(event) {
      if (event.canceled) {
        return "danger";
      }
      if (event.current) {
        return "primary";
      }
      if (event.changed) {
        return "error";
      }
      return "";
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = today();
    },
    swipeLeft() {
      if (this.showDateNav) {
        this.next();
      }
    },
    swipeRight() {
      if (this.showDateNav) {
        this.prev();
      }
    },

    showDay() {
      //console.log("showDate");

      this.view = this.views[0];
      if (this.allowedViews.length == 0 || this.allowedViews.includes("day")) {
        this.type = this.views[0];
      }
    },

    showEvent({ event }) {
      this.selectedEvent = event;
      this.selectedOpen = true;
      this.dialog = true;
    },

    updateTime() {
      setInterval(() => {
        if (this.cal) {
          this.cal.updateTimes();
        }
      }, 60 * 1000);
    },
    eventTooltip(event) {
      let result = "";
      if (event.current) {
        result += "aktueller ";
      } else if (event.past) {
        result += "vergangener ";
      } else if (event.future) {
        result += "zukünftiger ";
      }
      if (event.canceled) {
        result += "abgesagter ";
      }
      if (event.changed) {
        result += "geänderter ";
      }
      result += "Termin";
      if (event.staffOnly) {
        result += " (nur für LK)";
      }
      return result;
    },
  },
  created() {
    this.type = this.views.find(
      (el) => el.name == localStorage.getItem("calendarType" + this.name)
    );
    if (!this.type) {
      this.type = this.views[0];
    }
    this.setToday();
  },
  mounted() {
    this.ready = true;
    this.updateTime();
  },
});
</script>

<style scoped lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.canceled,
.canceled .v-event-summary {
  text-decoration-line: line-through;
}
</style>
